export interface GenericSnackbar {
    /** message displayed by snackbar */
    message: string;
    /** type of snackbar displayed to help differentiate between styles
     *  use this as the main way to change the styles of the snack bar
     *  Success - for when the snackbar displays successful message (ex. status 200 api call)
     *  Error - for when the snackbar displays error message (ex. status 400/500 api call)
     *  Info - for when the snackbar displays info message
     */
    type: SnackbarMessageType;
    /** flag for showing/hiding the confirm button on a snackbar (user acknowledgement) */
    showconfirmButton: boolean;
}

export enum SnackbarMessageType {
    SUCCESS,
    ERROR,
    INFO
}