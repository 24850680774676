import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { GenericSnackbar, SnackbarMessageType } from 'app/core/generic-model-types/snackbar.model';
import { NotificationSnackbarComponent } from 'app/shared/components/notification-snackbar/notification-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private horizontal: MatSnackBarHorizontalPosition = 'center';
  private vertical: MatSnackBarVerticalPosition = 'top';

  constructor(private _snackBar: MatSnackBar) {}

  private _openSnackbar(snackbarModel: GenericSnackbar, duration = 5000): void {
    this._snackBar.openFromComponent(NotificationSnackbarComponent, {
      verticalPosition: this.vertical,
      horizontalPosition: this.horizontal,
      duration: duration,
      data: snackbarModel,
    });
  }

  /** Opens the success notification snackbar
   *  @param message Message to display.
   *  @param duration Optional param to change duration from default (5000 or 5 seconds).
   */
  displaySuccess(message: string, duration = 5000): void {
    const snackbar: GenericSnackbar = {
      message: message,
      type: SnackbarMessageType.SUCCESS,
      showconfirmButton: true,
    };
    this._openSnackbar(snackbar, duration);
  }

  /** Opens the error notification snackbar
   *  @param message Message to display.
   *  @param duration Optional param to change duration from default (5000 or 5 seconds).
   */
  displayError(message: string, duration = 5000): void {
    const snackbar: GenericSnackbar = {
      message: message,
      type: SnackbarMessageType.ERROR,
      showconfirmButton: true,
    };
    this._openSnackbar(snackbar, duration);
  }
}
